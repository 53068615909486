import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const Security = () => (
  <Layout>
    <SEO title="Security" />
    <div style={{ padding: 100 }}>
      <h1>Security</h1>
      <p>
        As a data company, we take security seriously at Quantifai. For clarity
        on how we do so, we have published this guide to help you understand our
        approach.
      </p>
      <h3>Your Data is Physically Secure</h3>
      <p>
        Our applications, data stores, and application network are hosted by
        Amazon Web Services, in highly secure data center locations with
        industry-leading physical security and physical access controls.
      </p>
      <h3>Your Data is Encrypted</h3>
      <p>
        Encryption is enforced for data both at rest and in transit. All of our
        customer data is encrypted when it is stored. Backup data is stored with
        the same level of encryption integrity as primary data. Customer data is
        also encrypted while in transit, using only secure network protocols.
      </p>
      <h3>Your Data is Private</h3>
      <p>
        All customer data is owned by the customer that created it in the first
        place. A customer controls all access to their data, including but not
        limited to reading, writing, or deleting that data. We do not look into
        your account without your express permission.
      </p>
      <h3>Your Data is Electronically Secure</h3>
      <p>
        Customer data is stored in a database that uses only secure protocols
        for access and is completely segregated and independent from
        non-production environments. Database access is tightly blocked by a
        strict firewall and whitelists only the necessary applications by IP.
        Keys are rotated on a regular basis, and the details of every single
        access are logged.
      </p>
      <h3>Your Data is Backed Up</h3>
      <p>
        We make regular and redundant backups of your data. We implement a
        planned, automated, and diligently-followed backup plan with a declared
        RTO and RPO. Our backup system is specially designed to ensure smooth
        recovery in disaster cases.
      </p>
      <h3>People are Fully Trained</h3>
      <p>
        All of Quantifai's employees and contractors undergo training on privacy
        and proper handling of customer data on a regular basis (annually, and
        ad hoc if there is even the slightest change in policy). All parties
        employed by Quantifai are required to acknowledge and agree to
        Quantifai's information security policy, with refreshes on an annual
        basis.
      </p>
      <h3>People require Additional Access Controls</h3>
      <p>
        All of Quantifai's employees and contractors have their access tightly
        controlled and logged. Two-factor authentication is enforced for all.
        Additionally, a role-based access control layer has been implemented so
        that each specific part of the data stored is granted to a person only
        if needed. These access requirements are reviewed and refreshed/revoked
        regularly.
      </p>
      <Link to="/">Go back to the homepage</Link>
    </div>
  </Layout>
);

export default Security;
